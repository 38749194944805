import React from 'react';
import FormView from '../../../../components/Base/FormView';
import AB1111Service from './AB1111.service';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, getFormValues } from 'redux-form';
import { ExpansionList, ExpansionPanel, TextField, Button, DataTable, TableHeader, TableColumn, TableRow, TableBody, Card, TabsContainer, Tabs, Tab } from 'react-md';
import { validation, Textfield, TextfieldMask, Searchfield, Datepickerv2, CheckboxSelection } from '../../../../components/Form';
import PropTypes from 'prop-types';
import izitoast from 'izitoast';
import accounting from 'accounting-js';
import download from 'downloadjs'
import A1Service from '../A1/A1.service';

@reduxForm({form: 'AB1111Form', destroyOnUnmount: true})
@connect((state) => ({
    formData: getFormValues('AB1111Form')(state),
    dialog: getFormValues('GlobalDialog')(state),
    // H2H CORE
    auth: state.auth.company
    // H2H CORE
}))
class AB1111FormView extends FormView {
    service=AB1111Service;
    viewType=2;
    enableRedirect=false

    constructor(props) {
        super(props)
        this.state={
            ...this.state,
            firstLoad: true
        }
    }

    static contextTypes = {
        showDialog: PropTypes.func,
    };

    async updateData(res, value){
        res = await this.service.api.save(value)
    }

    async saveData(res, value){
        res = await this.service.api.save(value)
    }

    async initData() {
        var params = this.props.router.match.params
        let res = await this.service.api.getAB1111(params.npwp, params.tahun, params.masa, params.pembetulan);
        res.data.masaTahun = res.data.masa + " s.d " + res.data.masa2 + " " + res.data.tahun
        this.props.initialize(res.data);
    }

    async generateRandomString() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    async handleReport(){
        try {
            var p = this.props.router.match.params
            izitoast.info({
                title: "Print Report",
                message: "mohon ditunggu, sedang dalam proses."
            })
            var res = await A1Service.api.reportDownload(p.npwp, {
                reports: [
                  {
                    data: {
                      type: "Spt1111-AB",
                      npwp: p.npwp,
                      masa: p.masa,
                      tahun: p.tahun,
                      pembetulan: p.pembetulan,
                      ...this.props.formData
                    },
                    invoice: {},
                    mpk: {
                      signature: "78lRwyNhG4u3CLxibFSt26xme+pXgbXFZwlb36zdkaI=",
                    },
                  },
                ],
                config: {
                  skipInvalidSignature: false,
                  skipInvalidType: false,
                },
              })
            // var filename = res.headers["content-disposition"].split('=')[1].replace(/"/g,'');
            var filename = await this.generateRandomString() + '.pdf';
            download(res.data, filename)
            izitoast.success({
                title: "Print Report",
                message: "proses generate laporan berhasil dicetak."
            })
        } catch(e){
            try {
                izitoast.error({
                    title: "Gagal",
                    message: `Proses gagal dengan pesan ${e.response.data.message}`
                })
            } catch(e){
                izitoast.error({
                    title: "Gagal",
                    message: `Proses gagal dengan pesan ${e.message}`
                })
            }
        }
    }

    _barItem() {
        const { handleSubmit, submitting, valid, pristine } = this.props;
        var disabledSave = false
        try {
            if(localStorage.getItem('disabledSave') == 'true'){
                disabledSave = true
            } else {
                disabledSave = false
            }
        } catch(e){}
        return (
        <div>
            <Button secondary flat onClick={()=> { this.navService.back(); }} style={{marginRight: 16}}>Kembali</Button>
            <Button raised primary onClick={this.handleReport.bind(this)} style={{marginRight: 16}}>Report</Button>
            <Button raised primary disabled={disabledSave} onClick={handleSubmit(this.handleSave.bind(this))}>Simpan</Button>
        </div>
        )
    }

    num = 0
    handleHitungB2(str, value, prevValue){
        this.num++
        if (this.state.firstLoad) {
            this.setState({
                firstLoad: false
            })
            return null
        } else if (this.num > 2) {
            var params = this.props.router.match.params
            var masa = parseInt(params.masa)
            var tahun = parseInt(params.tahun)
            var ppnRate = 10
            if (tahun == 2022 && masa >= 4) {
                ppnRate = 11
            } else if (tahun > 2022) {
                ppnRate = 11
            }
            var ppn = (value * ppnRate) / 100
            this.props.change('section1.jmlIB2PPN', ppn)
            var dpps = 0
            var ppns = 0
            var ppnbm = 0
            if(this.props.formData && this.props.formData){
                var form = this.props.formData
                dpps  = value
                if(form.section1 && form.section1.jmlIB2PPN) ppns  = ppn
                if(form.section1 && form.section1.jmlIB2PPnBM) ppnbm  = form.section1.jmlIB2PPnBM
                var totalBDpp = dpps + form.section1.jmlIB1DPP
                var totalBppn = ppns + form.section1.jmlIB1PPN
                var totalPpnbm = ppnbm + form.section1.jmlIB1PPnBM
                var totalCDpp = form.section1.jmlIC2DPP + form.section1.jmlIC3DPP + form.section1.jmlIC4DPP
                var totalCPpn = form.section1.jmlIC2PPN + form.section1.jmlIC3PPN + form.section1.jmlIC4PPN
                var totalCPpnbm = form.section1.jmlIC2PPnBM + form.section1.jmlIC3PPnBM + form.section1.jmlIC4PPnBM
                dpps = totalBDpp - totalCDpp
                ppns = totalBppn - totalCPpn
                ppnbm = totalPpnbm - totalCPpnbm
            } 
            this.props.change('section1.jmlIC1PPN', ppns)
            this.props.change('section1.jmlIC1DPP', dpps)
            this.props.change('section1.jmlIC1PPnBM', ppnbm)
        }
    }

    handleHitungB2PPN(str, value, prevValue){    
        var ppns = 0
        if(this.props.formData && this.props.formData){
            var form = this.props.formData
            ppns  = value
            var totalBppn = ppns + form.section1.jmlIB1PPN
            var totalCPpn = form.section1.jmlIC2PPN + form.section1.jmlIC3PPN + form.section1.jmlIC4PPN
            var totalC1PPN = totalBppn - totalCPpn
            ppns = totalC1PPN
        } 
        this.props.change('section1.jmlIC1PPN', ppns)
    }
    
    handleHitungB2PPnBM(str, value, prevValue){
        var ppnbm = 0
        if(this.props.formData && this.props.formData){
            var form = this.props.formData
            if(form.section1 && form.section1.jmlIB2PPnBM) ppnbm  = value
            var totalPpnbm = ppnbm + form.section1.jmlIB1PPnBM
            var totalCPpnbm = form.section1.jmlIC2PPnBM + form.section1.jmlIC3PPnBM + form.section1.jmlIC4PPnBM
            ppnbm = totalPpnbm - totalCPpnbm
        } 
        this.props.change('section1.jmlIC1PPnBM', ppnbm)
    }

    handleHitung3B4(str, value, prevValue, name){
        var jmlIIIAPPN  = 0
        var jmlIIIB1PPN = 0
        var jmlIIIB2PPN = 0
        var jmlIIIB3PPN = 0
        if(name == "section3.jmlIIIB1PPN") jmlIIIB1PPN = value
        if(name == "section3.jmlIIIB2PPN") jmlIIIB2PPN = value
        if(name == "section3.jmlIIIB3PPN") jmlIIIB3PPN = value
        if(this.props.formData && this.props.formData.section3){
            if(this.props.formData.section3.jmlIIIAPPN) jmlIIIAPPN = this.props.formData.section3.jmlIIIAPPN
            if(this.props.formData.section3.jmlIIIB1PPN) jmlIIIB1PPN = this.props.formData.section3.jmlIIIB1PPN
            if(this.props.formData.section3.jmlIIIB2PPN) jmlIIIB2PPN = this.props.formData.section3.jmlIIIB2PPN
            if(this.props.formData.section3.jmlIIIB3PPN) jmlIIIB3PPN = this.props.formData.section3.jmlIIIB3PPN
        }
        var jmlIIIB4PPN = jmlIIIB1PPN + jmlIIIB2PPN + jmlIIIB3PPN
        var jmlIIICPPN = jmlIIIB4PPN + jmlIIIAPPN
    }

    componentWillReceiveProps(nextProps){
        try {
            var diff1 = ""
            var diff2 = ""
            if (this.props.formData && nextProps.formData) {
                if(this.props.formData) diff1 = JSON.stringify(this.props.formData)
                if(nextProps.formData) diff2 = JSON.stringify(nextProps.formData)
                if(diff1 !== diff2){
                    if(JSON.parse(diff2)){
                        var x = JSON.parse(diff2)
                        var jmlIIIB4PPN = x.section3.jmlIIIB1PPN + x.section3.jmlIIIB2PPN + x.section3.jmlIIIB3PPN
                        var jmlIIICPPN =  jmlIIIB4PPN + x.section3.jmlIIIAPPN
                        nextProps.change('section3.jmlIIIB4PPN', jmlIIIB4PPN)
                        nextProps.change('section3.jmlIIICPPN', jmlIIICPPN)
                        // var jmlIC1PPN = x.section1.jmlIC1PPN + x.section1.jmlIB2PPN
                        // var jmlIC1DPP = x.section1.jmlIC1DPP + x.section1.jmlIB2DPP
                    }
                }
            }
        } catch(e){
            console.log(e)
        }
    }

    async showDialogKompensasi() {
        var params = this.props.router.match.params
        try {
            izitoast.info({
                title: "Info",
                message: "Menarik Data dari DJP, mohon Tunggu."
            })
            let getKompensasi = await this.service.api.getKompensasi(params.npwp, params.masa, params.tahun)
            if (getKompensasi.data != null && getKompensasi.data.length > 0) {
                izitoast.success({
                    title: "Success",
                    message: "Berhasil Menarik Data dari DJP !"
                })
                var res = await this.context.showDialog(()=> ({
                    title: "Rincian Kompensasi",
                    initialValue: {},
                    width: 1000,
                    contentStyle: {
                        height: 270,
                        maxHeight: 500
                    },
                    cancelText: ' ',
                    text: (
                        <div>
                        <DataTable plain>
                            <TableHeader>
                                <TableRow>
                                <TableColumn>#</TableColumn>
                                <TableColumn>Dari Masa</TableColumn>
                                <TableColumn>Dari Tahun</TableColumn>
                                <TableColumn>Dari Pembetulan</TableColumn>
                                <TableColumn>Nilai Kompensasi</TableColumn>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {getKompensasi.data.map((d, i)=> {
                                    return (
                                        <TableRow key={i}>
                                            <TableColumn>{i + 1}</TableColumn>
                                            <TableColumn>{d.masaPajakAsal}</TableColumn>
                                            <TableColumn>{d.tahunPajakAsal}</TableColumn>
                                            <TableColumn>{d.revNoAsal}</TableColumn>
                                            <TableColumn>{accounting.formatNumber(d.nilaiKompensasi)}</TableColumn>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </DataTable>
                        </div>
                    )
                }))
            } else {
                izitoast.success({
                    title: "Success",
                    message: "Data tidak ditemukan"
                })
            }
        } catch (error) {}
    }

    async beforeSave(values){
        try {
            values.section1.jmlIADPP	=	Math.floor(values.section1.jmlIADPP)
            values.section1.jmlIB1DPP	=	Math.floor(values.section1.jmlIB1DPP)
            values.section1.jmlIB1PPN	=	Math.floor(values.section1.jmlIB1PPN)
            values.section1.jmlIB1PPnBM	=	Math.floor(values.section1.jmlIB1PPnBM)
            values.section1.jmlIB2DPP	=	Math.floor(values.section1.jmlIB2DPP)
            values.section1.jmlIB2PPN	=	Math.floor(values.section1.jmlIB2PPN)
            values.section1.jmlIB2PPnBM	=	Math.floor(values.section1.jmlIB2PPnBM)
            values.section1.jmlIC1DPP	=	Math.floor(values.section1.jmlIC1DPP)
            values.section1.jmlIC1PPN	=	Math.floor(values.section1.jmlIC1PPN)
            values.section1.jmlIC1PPnBM	=	Math.floor(values.section1.jmlIC1PPnBM)
            values.section1.jmlIC2DPP	=	Math.floor(values.section1.jmlIC2DPP)
            values.section1.jmlIC2PPN	=	Math.floor(values.section1.jmlIC2PPN)
            values.section1.jmlIC2PPnBM	=	Math.floor(values.section1.jmlIC2PPnBM)
            values.section1.jmlIC3DPP	=	Math.floor(values.section1.jmlIC3DPP)
            values.section1.jmlIC3PPN	=	Math.floor(values.section1.jmlIC3PPN)
            values.section1.jmlIC3PPnBM	=	Math.floor(values.section1.jmlIC3PPnBM)
            values.section1.jmlIC4DPP	=	Math.floor(values.section1.jmlIC4DPP)
            values.section1.jmlIC4PPN	=	Math.floor(values.section1.jmlIC4PPN)
            values.section1.jmlIC4PPnBM	=	Math.floor(values.section1.jmlIC4PPnBM)
            values.section2.jmlIIADPP	=	Math.floor(values.section2.jmlIIADPP)
            values.section2.jmlIIAPPN	=	Math.floor(values.section2.jmlIIAPPN)
            values.section2.jmlIIAPPnBM	=	Math.floor(values.section2.jmlIIAPPnBM)
            values.section2.jmlIIBDPP	=	Math.floor(values.section2.jmlIIBDPP)
            values.section2.jmlIIBPPN	=	Math.floor(values.section2.jmlIIBPPN)
            values.section2.jmlIIBPPnBM	=	Math.floor(values.section2.jmlIIBPPnBM)
            values.section2.jmlIICDPP	=	Math.floor(values.section2.jmlIICDPP)
            values.section2.jmlIICPPN	=	Math.floor(values.section2.jmlIICPPN)
            values.section2.jmlIICPPnBM	=	Math.floor(values.section2.jmlIICPPnBM)
            values.section2.jmlIIDDPP	=	Math.floor(values.section2.jmlIIDDPP)
            values.section2.jmlIIDPPN	=	Math.floor(values.section2.jmlIIDPPN)
            values.section2.jmlIIDPPnBM	=	Math.floor(values.section2.jmlIIDPPnBM)
            values.section3.jmlIIIAPPN	=	Math.floor(values.section3.jmlIIIAPPN)
            values.section3.jmlIIIB1PPN	=	Math.floor(values.section3.jmlIIIB1PPN)
            values.section3.jmlIIIB2PPN	=	Math.floor(values.section3.jmlIIIB2PPN)
            values.section3.jmlIIIB3PPN	=	Math.floor(values.section3.jmlIIIB3PPN)
            values.section3.jmlIIIB4PPN	=	Math.floor(values.section3.jmlIIIB4PPN)
            values.section3.jmlIIICPPN	=	Math.floor(values.section3.jmlIIICPPN)
        } catch(e){
            console.log(e, 'error pembulatan')
        }
        return values
    }

    formView() {
        return (
            <div>
                <Card>
                    <div className='md-grid'>
                        <Field
                            label='Nama Wajib Pajak'
                            name='company.name'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            disabled
                        />
                        <Field
                            label='NPWP'
                            name='company.npwp'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            disabled
                        />
                        {/* <Field
                            label='Alamat'
                            name='company.address'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            disabled
                        /> */}
                        <Field
                            label='Masa'
                            name='masaTahun'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            disabled
                        />
                        <Field
                            label='Pembetulan'
                            name='pembetulan'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            disabled
                        />
                    </div>
                </Card>
                <br />
                <Card>
                    <TabsContainer style={{color: 'black'}} panelClassName="md-grid">
                        <Tabs style={{color: 'black'}} tabId="switch-tab">
                            <Tab style={{color: 'black'}} label="Bagian I">
                                <DataTable plain style={{width: '100%'}}>
                                    <TableBody>
                                        <TableRow>
                                            <TableColumn colSpan={6}>I. REKAPITULASI PENYERAHAN</TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>A.</TableColumn>
                                            <TableColumn colSpan={2}>Ekspor BPK Berwujud / Ekspor BPK Tidak Berwujud / JKP</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIADPP'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn></TableColumn>
                                            <TableColumn></TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>B.</TableColumn>
                                            <TableColumn colSpan={2}>Penyerahan Dalam Negeri</TableColumn>
                                            <TableColumn></TableColumn>
                                            <TableColumn></TableColumn>
                                            <TableColumn></TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}></TableColumn>
                                            <TableColumn style={{width: 50}}>1.</TableColumn>
                                            <TableColumn>Penyerahan Dalam Negeri dengan Faktur Pajak yang Tidak Digunggung</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIB1DPP'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIB1PPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIB1PPnBM'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}></TableColumn>
                                            <TableColumn style={{width: 50}}>2.</TableColumn>
                                            <TableColumn>Penyerahan Dalam Negeri dengan Faktur Pajak yang Digunggung</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIB2DPP'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    onChange={this.handleHitungB2.bind(this)}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIB2PPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    onChange={this.handleHitungB2PPN.bind(this)}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIB2PPnBM'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    onChange={this.handleHitungB2PPnBM.bind(this)}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>C.</TableColumn>
                                            <TableColumn colSpan={2}>Perincian Penyerahan Dalam Negeri</TableColumn>
                                            <TableColumn></TableColumn>
                                            <TableColumn></TableColumn>
                                            <TableColumn></TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}></TableColumn>
                                            <TableColumn style={{width: 50}}>1.</TableColumn>
                                            <TableColumn>Penyerahan yang PPN atau PPN dan PPbBM-nya harus dipungut sendiri</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIC1DPP'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIC1PPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIC1PPnBM'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}></TableColumn>
                                            <TableColumn style={{width: 50}}>2.</TableColumn>
                                            <TableColumn>Penyerahan yang PPN atau PPN dan PPbBM-nya dipungut oleh Pemungut PPN</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIC2DPP'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIC2PPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIC2PPnBM'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}></TableColumn>
                                            <TableColumn style={{width: 50}}>3.</TableColumn>
                                            <TableColumn>Penyerahan yang PPN atau PPN dan PPbBM-nya tidak dipungut</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIC3DPP'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIC3PPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIC3PPnBM'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}></TableColumn>
                                            <TableColumn style={{width: 50}}>4.</TableColumn>
                                            <TableColumn>Penyerahan yang dibebaskan dari pengenaan PPN atau PPN dan PPbBM-nya</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIC4DPP'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIC4PPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIC4PPnBM'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                    </TableBody>
                                </DataTable>
                            </Tab>
                            <Tab style={{color: 'black'}} label="Bagian II">
                                <DataTable plain>
                                    <TableBody>
                                        <TableRow>
                                            <TableColumn colSpan={6}>II. REKAPITULASI PEROLEHAN</TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>A.</TableColumn>
                                            <TableColumn>Impor BKP, Pemanfaatan BKP tidak Berwujud dari Luar Daerah Pabean, dan Pemanfaatan JKP dari Luar Daerah Pabean yang PM-nya dapat Dikreditkan</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section2.jmlIIADPP'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section2.jmlIIAPPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section2.jmlIIAPPnBM'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>B.</TableColumn>
                                            <TableColumn>Perolehan BKP/JKP dari Dalam Negeri yang PM-nya Dapat Dikreditkan</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section2.jmlIIBDPP'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section2.jmlIIBPPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section2.jmlIIBPPnBM'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>C.</TableColumn>
                                            <TableColumn>Impor atau Perolehan yang PM-nya tidak Dapat Dikreditkan dan/atau impor atau Perolehan yang Mendapat Fasilitas</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section2.jmlIICDPP'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section2.jmlIICPPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section2.jmlIICPPnBM'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>D.</TableColumn>
                                            <TableColumn>Jumlah Perolehan (II.A + II.B + II.C)</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section2.jmlIIDDPP'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section2.jmlIIDPPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section2.jmlIIDPPnBM'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                    </TableBody>
                                </DataTable>
                            </Tab>
                            <Tab style={{color: 'black'}} label="Bagian III">
                                <DataTable plain style={{width: '100%'}}>
                                    <TableBody>
                                        <TableRow>
                                            <TableColumn colSpan={3}>III. PERHITUNGAN PM YANG DAPAT DIKREDITKAN</TableColumn>
                                        </TableRow>
                                        <TableRow>
                                        <TableColumn style={{width: 50}}>A.</TableColumn>
                                        <TableColumn colSpan={2}>Pajak Masukan atas Perolehan yang Dapat Dikreditkan</TableColumn>
                                        <TableColumn>
                                            <Field
                                                label=''
                                                name='section3.jmlIIIAPPN'
                                                className="md-cell md-cell--12"
                                                component={TextfieldMask}
                                                money={true} normalize={(e)=> parseFloat(e)}
                                                disabled={true}
                                            />
                                        </TableColumn>
                                    </TableRow>
                                        <TableRow>
                                        <TableColumn style={{width: 50}}>B.</TableColumn>
                                        <TableColumn colSpan={3}>Pajak Masukan Lainnya</TableColumn>
                                    </TableRow>
                                        <TableRow>
                                        <TableColumn style={{width: 50}}></TableColumn>
                                        <TableColumn style={{width: 50}}>1.</TableColumn>
                                        <TableColumn>Kompensasi Kelebihan PPN Masa Pajak sebelumnya</TableColumn>
                                        <TableColumn>
                                            <Field
                                                label=''
                                                name='section3.jmlIIIB1PPN'
                                                className="md-cell md-cell--12"
                                                component={TextfieldMask}
                                                money={true} normalize={(e)=> parseFloat(e)}
                                                onChange={this.handleHitung3B4.bind(this)}
                                                disabled={true}
                                            />
                                        </TableColumn>
                                    </TableRow>
                                        <TableRow>
                                        <TableColumn style={{width: 50}}></TableColumn>
                                        <TableColumn style={{width: 50}}>2.</TableColumn>
                                        <TableColumn>
                                            <div className="md-cell md-cell--12" style={{display: 'flex'}}>
                                                <div className="md-cell md-cell--8">
                                                    <p className="md-cell md-cell--6">
                                                        Kompensasi Kelebihan PPN karena pembetulan SPT PPN Masa Pajak
                                                    </p>
                                                    <Field
                                                        label='Masa'
                                                        name='section3.jmlIIIB2Masa'
                                                        className="md-cell md-cell--2"
                                                        component={Searchfield}
                                                        options={[
                                                            {id: "1", name: "1"},
                                                            {id: "2", name: "2"},
                                                            {id: "3", name: "3"},
                                                            {id: "4", name: "4"},
                                                            {id: "5", name: "5"},
                                                            {id: "6", name: "6"},
                                                            {id: "7", name: "7"},
                                                            {id: "8", name: "8"},
                                                            {id: "9", name: "9"},
                                                            {id: "10", name: "10"},
                                                            {id: "11", name: "11"},
                                                            {id: "12", name: "12"}
                                                        ]}
                                                        disabled={true}
                                                    />
                                                    <Field
                                                        label='Tahun'
                                                        name='section3.jmlIIIB2Tahun'
                                                        className="md-cell md-cell--4"
                                                        component={Textfield}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="md-cell md-cell--4" style={{textAlign: 'end'}}>
                                                    <Button style={{marginRight: 10}} raised primary onClick={this.showDialogKompensasi.bind(this)}>Lihat Detail Kompensasi</Button>
                                                </div>
                                            </div>
                                        </TableColumn>
                                        <TableColumn>
                                            <Field
                                                label=''
                                                name='section3.jmlIIIB2PPN'
                                                className="md-cell md-cell--12"
                                                component={TextfieldMask}
                                                money={true} normalize={(e)=> parseFloat(e)}
                                                onChange={this.handleHitung3B4.bind(this)}
                                                disabled={true}
                                            />
                                        </TableColumn>
                                    </TableRow>
                                        <TableRow>
                                        <TableColumn style={{width: 50}}></TableColumn>
                                        <TableColumn style={{width: 50}}>3.</TableColumn>
                                        <TableColumn>Hasil Perhitungan Kembali Pajak Masukan yang telah dikreditkan sebagai penambah (pengurang) Pajak Masukan</TableColumn>
                                        <TableColumn>
                                            <Field
                                                label=''
                                                name='section3.jmlIIIB3PPN'
                                                className="md-cell md-cell--12"
                                                component={TextfieldMask}
                                                money={true} normalize={(e)=> parseFloat(e)}
                                                onChange={this.handleHitung3B4.bind(this)}
                                            />
                                        </TableColumn>
                                    </TableRow>
                                        <TableRow>
                                        <TableColumn style={{width: 50}}></TableColumn>
                                        <TableColumn style={{width: 50}}>4.</TableColumn>
                                        <TableColumn>Jumlah</TableColumn>
                                        <TableColumn>
                                            <Field
                                                label=''
                                                name='section3.jmlIIIB4PPN'
                                                className="md-cell md-cell--12"
                                                component={TextfieldMask}
                                                money={true} normalize={(e)=> parseFloat(e)}
                                                disabled={true}
                                            />
                                        </TableColumn>
                                    </TableRow>
                                        <TableRow>
                                        <TableColumn style={{width: 50}}>C.</TableColumn>
                                        <TableColumn colSpan={2}>Jumlah Pajak Masukan yang dapat Diperhitungkan</TableColumn>
                                        <TableColumn>
                                            <Field
                                                label=''
                                                name='section3.jmlIIICPPN'
                                                className="md-cell md-cell--12"
                                                component={TextfieldMask}
                                                money={true} normalize={(e)=> parseFloat(e)}
                                                disabled={true}
                                            />
                                        </TableColumn>
                                    </TableRow>
                                    </TableBody>
                                </DataTable>
                            </Tab>
                        </Tabs>
                    </TabsContainer>
                </Card>
            </div>
        )
    }

};

export default AB1111FormView;