import React from 'react';
import FormView from '../../../../components/Base/FormView';
import Induk1111Service from './Induk1111.service';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, getFormValues } from 'redux-form';
import { ExpansionList, ExpansionPanel, TextField, Button, DataTable, TableHeader, TableColumn, TableRow, TableBody, Card, TabsContainer, Tabs, Tab } from 'react-md';
import { validation, Textfield, TextfieldMask, Searchfield, Datepickerv2, Checkbox, CheckboxSelection } from '../../../../components/Form';
import PropTypes from 'prop-types';
import izitoast from 'izitoast';
import accounting from 'accounting-js';
import FileSaver, { saveAs } from 'file-saver';
import download from 'downloadjs'
import SSPIIView from './../SSPII/SSPII.view.select';
import SSPIIIView from './../SSPIII/SSPIII.view.select';
import SSPIVView from './../SSPIV/SSPIV.view.select';
import SSPVView from './../SSPV/SSPV.view.select';
import A1Service from '../A1/A1.service';

@reduxForm({form: 'Induk1111Form', destroyOnUnmount: true})
@connect((state) => ({
    formData: getFormValues('Induk1111Form')(state),
    dialog: getFormValues('GlobalDialog')(state),
    // H2H CORE
    auth: state.auth.company
    // H2H CORE
}))
class Induk1111FormView extends FormView {
    service=Induk1111Service;
    viewType=2;

    enableRedirect=false
    static contextTypes = {
        showDialog: PropTypes.func,
    };

    initialData = {
        chkPKP: false,
        chkKuasa: false
    }

    async updateData(res, value){
        res = await this.service.api.save(value)
    }

    async saveData(res, value){
        res = await this.service.api.save(value)
    }

    async initData() {
        var params = this.props.router.match.params
        let res2 = {
            totalPpnDdm: 0
        }
        try{
            res2 = await this.service.api.getPpnDdm(params.npwp, params.tahun, params.masa, params.pembetulan);
        } catch(e) {}
        let res = await this.service.api.getInduk1111New(params.npwp, params.tahun, params.masa, params.pembetulan, res2.totalPpnDdm);
        res.data.masaTahun = res.data.masa + " s.d " + res.data.masa + " " + res.data.tahun
        this.props.initialize(res.data)
    }

    async generateRandomString() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    async handleReport(){
        try {
            var p = this.props.router.match.params
            izitoast.info({
                title: "Print Report",
                message: "mohon ditunggu, sedang dalam proses."
            })
            var res = await A1Service.api.reportDownload(p.npwp, {
                reports: [
                  {
                    data: {
                      type: "Spt1111-Induk",
                      npwp: p.npwp,
                      masa: p.masa,
                      tahun: p.tahun,
                      pembetulan: p.pembetulan,
                      ...this.props.formData
                    },
                    invoice: {},
                    mpk: {
                      signature: "78lRwyNhG4u3CLxibFSt26xme+pXgbXFZwlb36zdkaI=",
                    },
                  },
                ],
                config: {
                  skipInvalidSignature: false,
                  skipInvalidType: false,
                },
              })
            // var filename = res.headers["content-disposition"].split('=')[1].replace(/"/g,'');
            var filename = await this.generateRandomString() + '.pdf';
            download(res.data, filename)
            izitoast.success({
                title: "Print Report",
                message: "proses generate laporan berhasil dicetak."
            })
        } catch(e){
            try {
                izitoast.error({
                    title: "Gagal",
                    message: `Proses gagal dengan pesan ${e.response.data.message}`
                })
            } catch(e){
                izitoast.error({
                    title: "Gagal",
                    message: `Proses gagal dengan pesan ${e.message}`
                })
            }
        }
    }

    _barItem() {
        const { handleSubmit, submitting, valid, pristine } = this.props;
        var disabledSave = false
        try {
            if(localStorage.getItem('disabledSave') == 'true'){
                disabledSave = true
            } else {
                disabledSave = false
            }
        } catch(e){}
        return (
        <div>
            <Button secondary flat onClick={()=> { this.navService.back(); }} style={{marginRight: 16}}>Kembali</Button>
            <Button raised primary onClick={this.handleReport.bind(this)} style={{marginRight: 16}}>Report</Button>
            <Button raised primary disabled={disabledSave} onClick={handleSubmit(this.handleSave.bind(this))}>Simpan</Button>
        </div>
        )
    }

    handleJmlIBDPP(str, value){
        if(this.props.formData && this.props.formData){
            var form = this.props.formData
            var totalA = 0
            var totalB = value
            if(form.section1 && form.section1.jmlIATotDPP) totalA = form.section1.jmlIATotDPP
            var totalC = totalA + totalB
            this.props.change('section1.jmlICDPP', totalC)
        }
    }

    hitungBagianIIB(valStr, value){
        var pembetulan = false
        if(this.props.formData && this.props.formData.pembetulan && this.props.formData.pembetulan > 0) pembetulan = true
        var nilaiBagianIIA = 0
        var nilaiBagianIIB = 0
        var nilaiBagianIIC = 0
        var nilaiBagianIID = 0
        var nilaiBagianIIE = 0
        var nilaiBagianIIF = 0
        if(this.props.formData && this.props.formData){
            var form = this.props.formData
            if(form.section2Ag){
                if(form.section2Ag.jmlIIAPPN) nilaiBagianIIA = form.section2Ag.jmlIIAPPN
                if(form.section2Ag.jmlIIBPPN) nilaiBagianIIB = form.section2Ag.jmlIIBPPN
                if(form.section2Ag.jmlIICPPN) nilaiBagianIIC = form.section2Ag.jmlIICPPN
                if(form.section2Ag.jmlIIDPPN) nilaiBagianIID = form.section2Ag.jmlIIDPPN
                if(form.section2Ag.jmlIIEPPN) nilaiBagianIIE = form.section2Ag.jmlIIEPPN
                if(form.section2Ag.jmlIIFPPN) nilaiBagianIIF = form.section2Ag.jmlIIFPPN
            }
        }
        // PEMBETULAN 0
        if(!pembetulan){
            // nilaiBagianIID = nilaiBagianIID - value
            nilaiBagianIID = nilaiBagianIIA - value - nilaiBagianIIC
            this.props.change("section2Ag.jmlIIDPPN", nilaiBagianIID)
        }
        // PEMBETULAN > 0
        if(pembetulan) {
            nilaiBagianIID = nilaiBagianIIA - value - nilaiBagianIIC
            nilaiBagianIIF = nilaiBagianIID - nilaiBagianIIE
            this.props.change("section2Ag.jmlIIDPPN", nilaiBagianIID)
            this.props.change("section2Ag.jmlIIFPPN", nilaiBagianIIF)
        }
    }

    hitungBagianIIE(valStr, value){
        var pembetulan = false
        if(this.props.formData && this.props.formData.pembetulan && this.props.formData.pembetulan > 0) pembetulan = true
        var nilaiBagianIIA = 0
        var nilaiBagianIIB = 0
        var nilaiBagianIIC = 0
        var nilaiBagianIID = 0
        var nilaiBagianIIE = 0
        var nilaiBagianIIF = 0
        if(this.props.formData){
            var form = this.props.formData
            if(form.section2Ag){
                if(form.section2Ag.jmlIIAPPN) nilaiBagianIIA = form.section2Ag.jmlIIAPPN
                if(form.section2Ag.jmlIIBPPN) nilaiBagianIIB = form.section2Ag.jmlIIBPPN
                if(form.section2Ag.jmlIICPPN) nilaiBagianIIC = form.section2Ag.jmlIICPPN
                if(form.section2Ag.jmlIIDPPN) nilaiBagianIID = form.section2Ag.jmlIIDPPN
                if(form.section2Ag.jmlIIEPPN) nilaiBagianIIE = form.section2Ag.jmlIIEPPN
                if(form.section2Ag.jmlIIFPPN) nilaiBagianIIF = form.section2Ag.jmlIIFPPN
            }
        }
        // PEMBETULAN > 0
        if(pembetulan) {
            nilaiBagianIIF = nilaiBagianIID - value
            this.props.change("section2Ag.jmlIIFPPN", nilaiBagianIIF)
        }
    }

    handleSwitchA(){
        if(this.props.formData){
            var form = this.props.formData
            if(form.section2h && form.section2h.chkLB2) {
                this.props.change('section2h.chkLB3', true)
            } else {
                this.props.change('section2h.chkLB3', false)
            }
            if(form.section2h && form.section2h.chkLB3) {
                this.props.change('section2h.chkLB2', true)
            } else {
                this.props.change('section2h.chkLB2', false)
            }
            if(form.section2h && form.section2h.chkLB4) {
                this.props.change('section2h.chkLB5', true)
            } else {
                this.props.change('section2h.chkLB5', false)
            }
            if(form.section2h && form.section2h.chkLB5) {
                this.props.change('section2h.chkLB4', true)
            } else {
                this.props.change('section2h.chkLB4', false)
            }
        }
      }
    
      handleSwitchB(){
        if(this.props.formData && this.props.formData){
            var form = this.props.formData
            if(form.section2h && form.section2h.chkLB4) {
                this.props.change('section2h.chkLB5', true)
            } else {
                this.props.change('section2h.chkLB5', false)
            }
            if(form.section2h && form.section2h.chkLB5) {
                this.props.change('section2h.chkLB4', true)
            } else {
                this.props.change('section2h.chkLB4', false)
            }
        }
      }
    
      handleSwitchC(param){
        if(this.props.formData && this.props.formData){
            var form = this.props.formData
            if(param == 'B6'){
                this.props.change('section2h.chkLB6', true)
                this.props.change('section2h.chkLB7', false)
                this.props.change('section2h.chkLB8', false)
                this.props.change('section2h.masaPajakLb', null)
                this.props.change('section2h.tahunPajakLb', null)
                this.props.change('section2h.chkLB9', false)
                this.props.change('section2h.chkLB10', false)
                this.props.change('section2h.chkLB11', false)
                this.props.change('section2h.chkLB12', false)
                this.props.change('section2h.chkLB13', false)
                this.props.change('section2h.chkLB14', false)
                this.props.change('section2h.chkLB15', false)
            }
            if(param == 'B7'){
                this.props.change('section2h.chkLB6', false)
                this.props.change('section2h.chkLB7', true)
                this.props.change('section2h.chkLB8', false)
                this.props.change('section2h.chkLB9', false)
                this.props.change('section2h.masaPajakLb', null)
                this.props.change('section2h.tahunPajakLb', null)
                this.props.change('section2h.chkLB10', false)
                this.props.change('section2h.chkLB11', false)
                this.props.change('section2h.chkLB12', false)
                this.props.change('section2h.chkLB13', false)
                this.props.change('section2h.chkLB14', false)
                this.props.change('section2h.chkLB15', false)
            }
            if(param == 'B8'){
                this.props.change('section2h.chkLB6', false)
                this.props.change('section2h.chkLB7', false)
                this.props.change('section2h.chkLB8', true)
                this.props.change('section2h.masaPajakLb', null)
                this.props.change('section2h.tahunPajakLb', null)
                this.props.change('section2h.chkLB9', false)
                this.props.change('section2h.chkLB10', false)
                this.props.change('section2h.chkLB11', false)
                this.props.change('section2h.chkLB12', false)
                this.props.change('section2h.chkLB13', false)
                this.props.change('section2h.chkLB14', false)
                this.props.change('section2h.chkLB15', false)
            }
            if(param == 'B9'){
                this.props.change('section2h.chkLB9', true)
                this.props.change('section2h.chkLB12', false)
                this.props.change('section2h.chkLB13', false)
                this.props.change('section2h.chkLB14', false)
                this.props.change('section2h.chkLB15', false)
            }
            if(param == 'B10'){
                this.props.change('section2h.chkLB10', true)
                this.props.change('section2h.chkLB11', false)
            }
            if(param == 'B11'){
                this.props.change('section2h.chkLB10', false)
                this.props.change('section2h.chkLB11', true)
            }
            if(param == 'B12'){
                this.props.change('section2h.chkLB9', false)
                this.props.change('section2h.chkLB12', true)
                this.props.change('section2h.chkLB10', false)
                this.props.change('section2h.chkLB11', false)
                this.props.change('section2h.chkLB15', false)
            }
            if(param == 'B13'){
                this.props.change('section2h.chkLB13', true)
                this.props.change('section2h.chkLB14', false)
            }
            if(param == 'B14'){
                this.props.change('section2h.chkLB13', false)
                this.props.change('section2h.chkLB14', true)
            }
            if(param == 'B15'){
                this.props.change('section2h.chkLB9', false)
                this.props.change('section2h.chkLB12', false)
                this.props.change('section2h.chkLB11', false)
                this.props.change('section2h.chkLB13', false)
                this.props.change('section2h.chkLB14', false)
                this.props.change('section2h.chkLB10', false)
                this.props.change('section2h.chkLB15', true)
            }
        }
    }

    hitung3A(valStr, value){
        var params = this.props.router.match.params
        var masa = parseInt(params.masa)
        var tahun = parseInt(params.tahun)
        if (tahun < 2022) {
            var ppn = value * 10 / 100
            this.props.change("section3.jmlIIIB", Math.floor(ppn))
        } else if (tahun === 2022 && masa <4) {
            var ppn = value * 10 / 100
            this.props.change("section3.jmlIIIB", Math.floor(ppn))
        } else {
            var ppn = value * 2.2 / 100
            this.props.change("section3.jmlIIIB", Math.floor(ppn))
        }
        // var ppn = value * 10/100
        // this.props.change("section3.jmlIIIB", ppn)
    }

    hitung5B(valStr, value){
        var section5A = 0
        var section5B = 0
        var section5C = 0
        if(this.props.formData){
            var form = this.props.formData
            if(form.section5){
                if(form.section5.jmlVAPPN) section5A = form.section5.jmlVAPPN
                if(form.section5.jmlVBPPN) section5B = form.section5.jmlVBPPN
            }
        }
        var section5C = section5A - section5B
        this.props.change("section5.jmlVCPPN", section5C)
    }
    
    handleSelectSSP(resolve, sspNo, sspData){
        try {
            if(sspNo == "2"){
                this.props.change('section2Ag.tglKrgByrII', sspData.tanggalPembayaran)
                this.props.change('section2Ag.ntpnII', sspData.nomorPembayaran)
            }
            if(sspNo == "3"){
                this.props.change('section3.tglKrgByrIII', sspData.tanggalPembayaran)
                this.props.change('section3.ntpnIII', sspData.nomorPembayaran)
            }
            if(sspNo == "4"){
                this.props.change('section4.tglKrgByrIV', sspData.tanggalPembayaran)
                this.props.change('section4.ntpnIV', sspData.nomorPembayaran)
            }
            if(sspNo == "5"){
                this.props.change('section5.tglKrgByrV', sspData.tanggalPembayaran)
                this.props.change('section5.ntpnV', sspData.nomorPembayaran)
            }
        } catch(e){
            console.log(e)
        }
        resolve("Ok")
    }

    async selectSSP(sspNo){
        // SSP ADDON
        var pengurangSSPII = 0
        var pengurangSSPIII = 0
        var pengurangSSPIV = 0
        var pengurangSSPV = 0
        //SSP II
        if(this.props.formData && this.props.formData.pembetulan && this.props.formData.pembetulan > 0){
            if(this.props.formData && this.props.formData.section2Ag && this.props.formData.section2Ag.jmlIIFPPN) pengurangSSPII = this.props.formData.section2Ag.jmlIIFPPN 
        } else {
            if(this.props.formData && this.props.formData.section2Ag && this.props.formData.section2Ag.jmlIIDPPN) pengurangSSPII = this.props.formData.section2Ag.jmlIIDPPN
        }

        //SSP III
        if(this.props.formData && this.props.formData.section3 && this.props.formData.section3.jmlIIIB) pengurangSSPIII = this.props.formData.section3.jmlIIIB

        //SSP IV
        if(this.props.formData && this.props.formData.section4 && this.props.formData.section4.jmlIVA) pengurangSSPIV = this.props.formData.section4.jmlIVA

        //SSP V
        if(this.props.formData && (this.props.formData.pembetulan || this.props.formData.pembetulan == 0)){
            if(this.props.formData && this.props.formData.section5 && this.props.formData.section5.jmlVDPPN) pengurangSSPV = this.props.formData.section5.jmlVDPPN 
        }
        if(this.props.formData && this.props.formData.pembetulan && this.props.formData.pembetulan > 0){
            if(this.props.formData && this.props.formData.section5 && this.props.formData.section5.jmlVEPPN) pengurangSSPV = this.props.formData.section5.jmlVEPPN 
        }
        // SSP ADDON
        var _this = this
        var res = await this.context.showDialog((props, resolve, reject)=> ({
            title: null,
            initialValue: {},
            width: 800,
            height: 600,
            contentStyle: {
                height: 600,
                maxHeight: 600,
                padding: 0
            },
            onHide: ()=> { resolve("OK") },
            okText: "Tutup",
            cancelText: " ",
            text: (
                <div style={{ height: "100%", width: "100%"}}>
                    {sspNo == "2" && <SSPIIView router={this.props.router} pengurang={pengurangSSPII} onSelectSSP={_this.handleSelectSSP.bind(_this, resolve, sspNo)}  />}
                    {sspNo == "3" && <SSPIIIView router={this.props.router} pengurang={pengurangSSPIII} onSelectSSP={_this.handleSelectSSP.bind(_this, resolve, sspNo)}  />}
                    {sspNo == "4" && <SSPIVView router={this.props.router} pengurang={pengurangSSPIV} onSelectSSP={_this.handleSelectSSP.bind(_this, resolve, sspNo)}  />}
                    {sspNo == "5" && <SSPVView router={this.props.router} pengurang={pengurangSSPV} onSelectSSP={_this.handleSelectSSP.bind(_this, resolve, sspNo)}  />}
                </div>
            )
        }))
    }

    chkPkp(evt, e){
        this.props.change('section6.chkPKP', true)
        this.props.change('section6.chkKuasa', false)
    }

    chkKuasa(evt, e){
      this.props.change('section6.chkPKP', false)
      this.props.change('section6.chkKuasa', true)
    }

    async redirectPPNDDM() {
        var path = this.props.router.match.url
        var newPath = path.replace("/induk", "")
        this.props.router.history.push(`${newPath}/uangdimuka`)
    }

    chk2h(data){
        if(data.chkLB7) { 
            if (data.masaPajakLb == null) {
                izitoast.error({
                    title: "Section 2H",
                    message: "Masa Pajak Bagian 2.H Harus di Isi"
                })
            } else if (data.tahunPajakLb == null) {
                izitoast.error({
                    title: "Section 2H",
                    message: "Tahun Pajak Bagian 2.H Harus di Isi"
                })
            }
        } else {
            if (!data.chkLB8 && !data.chkLB7) {
                izitoast.error({
                    title: "Section 2H",
                    message: "Point 3.1 atau 3.2 Harus di pilih salah satu."
                })
            }
        }
    }

    async beforeSave(values){
        var nilaiIIF = 0
        try {
            if(this.props.formData){
                if(this.props.formData.pembetulan > 0){
                    var form = this.props.formData
                    if(form.section2Ag){
                        if(form.section2Ag.jmlIIFPPN) nilaiIIF = form.section2Ag.jmlIIFPPN
                    }
                    if(nilaiIIF < 0) {
                        this.chk2h(values.section2h)
                    }
                }
            }
            var pbt = this.props.formData.pembetulan

            values.section1.jmlIA1DPP	    =	Math.floor(values.section1.jmlIA1DPP)
            values.section1.jmlIA2DPP	    =	Math.floor(values.section1.jmlIA2DPP)
            values.section1.jmlIA2PPN	    =	Math.floor(values.section1.jmlIA2PPN)
            values.section1.jmlIA3DPP	    =	Math.floor(values.section1.jmlIA3DPP)
            values.section1.jmlIA3PPN	    =	Math.floor(values.section1.jmlIA3PPN)
            values.section1.jmlIA4DPP	    =	Math.floor(values.section1.jmlIA4DPP)
            values.section1.jmlIA4PPN	    =	Math.floor(values.section1.jmlIA4PPN)
            values.section1.jmlIA5DPP	    =	Math.floor(values.section1.jmlIA5DPP)
            values.section1.jmlIA5PPN	    =	Math.floor(values.section1.jmlIA5PPN)
            values.section1.jmlIATotDPP	    =	Math.floor(values.section1.jmlIATotDPP)
            values.section1.jmlIATotPPN	    =	Math.floor(values.section1.jmlIATotPPN)
            values.section1.jmlIBDPP	    =	Math.floor(values.section1.jmlIBDPP)
            values.section1.jmlICDPP	    =	Math.floor(values.section1.jmlICDPP)
            values.section2Ag.jmlIIAPPN	    =	Math.floor(values.section2Ag.jmlIIAPPN)
            values.section2Ag.jmlIIBPPN	    =	Math.floor(values.section2Ag.jmlIIBPPN)
            values.section2Ag.jmlIICPPN	    =	Math.floor(values.section2Ag.jmlIICPPN)
            values.section2Ag.jmlIIDPPN	    =	Math.floor(values.section2Ag.jmlIIDPPN)
            values.section2Ag.jmlIIEPPN	    =	Math.floor(values.section2Ag.jmlIIEPPN)
            values.section2Ag.jmlIIFPPN	    =	Math.floor(values.section2Ag.jmlIIFPPN)
            values.section3.jmlIIIA	        =	Math.floor(values.section3.jmlIIIA)
            values.section3.jmlIIIB	        =	Math.floor(values.section3.jmlIIIB)
            values.section4.jmlIVA	        =	Math.floor(values.section4.jmlIVA)
            values.section5.jmlVAPPN	    =	Math.floor(values.section5.jmlVAPPN)
            values.section5.jmlVBPPN	    =	Math.floor(values.section5.jmlVBPPN)
            values.section5.jmlVCPPN	    =	Math.floor(values.section5.jmlVCPPN)
            values.section5.jmlVDPPN	    =	Math.floor(values.section5.jmlVDPPN)
            window.wtf1 = values.section2Ag.chkNKLII
            
            if (pbt == 0) {
                if (values.section2Ag.jmlIIDPPN <= 0) {
                    values.section2Ag.ntpnII = null
                    values.section2Ag.tglKrgByrII = null
                }
            } else {
                if (values.section2Ag.jmlIIFPPN <= 0) {
                    values.section2Ag.ntpnII = null
                    values.section2Ag.tglKrgByrII = null
                }
            }
            // if(values.section2Ag.chkNKLII != 1){
            //     window.wtf2 = values.section2Ag.chkNKLII != 1
            //     values.section2Ag.ntpnII = null
            //     values.section2Ag.tglKrgByrII = null
            // }
            if(values.section3.ntpnIII == ""){
                values.section3.ntpnIII = null
                values.section3.tglKrgByrIII = null
            }
            if(values.section4.ntpnIV == ""){
                values.section4.ntpnIV = null
                values.section4.tglKrgByrIV = null
            }
            if(values.section5.ntpnV == ""){
                values.section5.ntpnV = null
                values.section5.tglKrgByrV = null
            }
        } catch(e){
            console.log(e, 'error pembulatan')
        }
        window.wtf = JSON.stringify(values)
        return values
    }

    formView() {
        // SPT INPUT RULE
        var pembetulan = false
        var isPembetulan = false
        try {
            if(this.props.formData){
                pembetulan = this.props.formData.pembetulan
            }
            if(pembetulan == 0){
                isPembetulan = false
            } else {
                isPembetulan = true
            }
        } catch(e){
            console.log(e)
        }
        var disabledSSPII = true
        var disabledSSPIII = true
        var disabledSSPIV = true
        var disabledSSPV = true
        var disabledBagian2H = false
        var nilaiBagianIID = 0
        var nilaiBagianIIF = 0
        var nilaiBagianIIIA = 0
        var nilaiBagianIVA = 0
        var nilaiBagianVE = 0
        var disabledHMasa = true
        var disabledRestitusi = true
        var checkH31 = false
        var checkH32 = false
        if(this.props.formData && this.props.formData){
            var form = this.props.formData
            if(form.section2Ag){
                if(form.section2Ag.jmlIIDPPN) nilaiBagianIID = form.section2Ag.jmlIIDPPN
                if(form.section2Ag.jmlIIFPPN) nilaiBagianIIF = form.section2Ag.jmlIIFPPN
            }
            if(form.section3){
                if(form.section3.jmlIIIA) nilaiBagianIIIA = form.section3.jmlIIIA
            }
            if(form.section4){
                if(form.section4.jmlIVA) nilaiBagianIVA = form.section4.jmlIVA
            }
            if(form.section5){
                if(form.section5.jmlVEPPN) nilaiBagianVE = form.section5.jmlVEPPN
            }
            if(form.section2h){
                
                if(form.section2h.chkLB7 == true) disabledHMasa = false
                if(form.section2h.chkLB8 == true) disabledRestitusi = false
            }
        }
        // if(isPembetulan == false){
        //     if(nilaiBagianIID <= 0) disabledBagian2H = true
        //     if(nilaiBagianIID > 0) disabledBagian2H = false   
        // }
        // if(isPembetulan == true){
        //     if(nilaiBagianIIF <= 0) disabledBagian2H = true
        //     if(nilaiBagianIIF > 0) disabledBagian2H = false   
        // }
        if(nilaiBagianIID > 0 && isPembetulan == false) disabledSSPII = false
        if(nilaiBagianIIF > 0 && isPembetulan == true)  disabledSSPII = false
        if(nilaiBagianIIIA > 0) disabledSSPIII = false
        if(nilaiBagianIVA > 0) disabledSSPIV = false
        if(nilaiBagianVE > 0) disabledSSPV = false
        // SPT INPUT RULE
        // STATUS SPT
        var nilaiPPN_status   = ""
        var nilaiPPNBM_status = ""
        if(this.props.formData && this.props.formData){
            var form = this.props.formData
            if(form.section2Ag){
                if(form.section2Ag.chkNKLII == 0) nilaiPPN_status = "NILAI PPN = NIHIL"
                if(form.section2Ag.chkNKLII == 1) nilaiPPN_status = "NILAI PPN = KURANG BAYAR"
                if(form.section2Ag.chkNKLII == 2) nilaiPPN_status = "NILAI PPN = LEBIH BAYAR"
            }
            if(form.section5){
                if(form.section5.chkNKLV == 0) nilaiPPNBM_status = "NILAI PPNBM = NIHIL"
                if(form.section5.chkNKLV == 1) nilaiPPNBM_status = "NILAI PPNBM = KURANG BAYAR"
                if(form.section5.chkNKLV == 2) nilaiPPNBM_status = "NILAI PPNBM = LEBIH BAYAR"
            }
        }
        // STATUS SPT
        var disabledKodeBillingD = true
        if(nilaiBagianIID > 0) disabledKodeBillingD = false
        var disabledKodeBillingF = true
        if(nilaiBagianIIF > 0) disabledKodeBillingF = false
        // NMLAMPIRAN 10
        var showLampiran10 = false
        if(this.props.formData && this.props.formData.section6 && this.props.formData.section6.chkLamp10) showLampiran10 = true
        if(isPembetulan == false){
            if(nilaiBagianIID < 0) {
                disabledBagian2H = true
                checkH31 = false
                checkH32 = true
            }
            if(nilaiBagianIID >= 0) disabledBagian2H = false   
        }
        if(isPembetulan == true){
            if(nilaiBagianIIF < 0) {
                disabledBagian2H = true
                checkH31 = true
                checkH32 = false
            }
            if(nilaiBagianIIF >= 0) disabledBagian2H = false   
        }
        // if(this.props.formData && this.props.formData){
        //     var form = this.props.formData
        //     if(form.section2Ag){
        //         if (form.section2Ag.chkNKLII == 2 && pembetulan == 0) {
        //             checkH31 = false
        //             checkH32 = true
        //         }
        //         if (form.section2Ag.chkNKLII == 2 && pembetulan !== 0) {
        //             checkH31 = true
        //             checkH32 = false
        //         }
        //     }
        // }
        return (
            <div>
                <Card>
                    <div className='md-grid'>
                        <Field
                            label='Nama Wajib Pajak'
                            name='company.name'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            disabled
                        />
                        <Field
                            label='NPWP'
                            name='company.npwp'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            disabled
                        />
                        <Field
                            label='Alamat'
                            name='company.address'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            disabled
                        />
                        <Field
                            label='Masa'
                            name='masaTahun'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            disabled
                        />
                        <div className="md-cell md-cell--6"></div>
                        <Field
                            label='Pembetulan'
                            name='pembetulan'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            disabled
                        />
                    </div>
                </Card>
                <br />
                <Card>
                    <TabsContainer style={{color: 'black'}} panelClassName="md-grid">
                        <Tabs style={{color: 'black'}} tabId="switch-tab">
                            <Tab style={{color: 'black'}} label="Bagian I">
                                <DataTable plain style={{width: '100%'}}>
                                    <TableBody>
                                        <TableRow>
                                            <TableColumn colSpan={5}>I. PENYERAHAN BARANG DAN JASA</TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>A.</TableColumn>
                                            <TableColumn colSpan={2}>Ekspor BPK Berwujud / Ekspor BPK Tidak Berwujud / JKP</TableColumn>
                                            <TableColumn></TableColumn>
                                            <TableColumn></TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}></TableColumn>
                                            <TableColumn style={{width: 50}}>1.</TableColumn>
                                            <TableColumn>Ekspor</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIA1DPP'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn></TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}></TableColumn>
                                            <TableColumn style={{width: 50}}>2.</TableColumn>
                                            <TableColumn>Penyerahan yang PPN-nya harus dipungut sendiri</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIA2DPP'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIA2PPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}></TableColumn>
                                            <TableColumn style={{width: 50}}>3.</TableColumn>
                                            <TableColumn>Penyerahan yang PPN-nya dipungut oleh Pemungut PPN</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIA3DPP'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIA3PPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}></TableColumn>
                                            <TableColumn style={{width: 50}}>4.</TableColumn>
                                            <TableColumn>Penyerahan yang PPN-nya tidak dipungut</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIA4DPP'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIA4PPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}></TableColumn>
                                            <TableColumn style={{width: 50}}>5.</TableColumn>
                                            <TableColumn>Penyerahan yang dibebaskan dari pengenaan PPN</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIA5DPP'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIA5PPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}></TableColumn>
                                            <TableColumn colSpan={2}>Jumlah(I.A.1 + I.A.2 + I.A.3 + I.A.4 + I.A.5)</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIATotDPP'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIATotPPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>B.</TableColumn>
                                            <TableColumn colSpan={2}>Tidak terutang PPN</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlIBDPP'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={this.props.dashboard}
                                                    onChange={this.handleJmlIBDPP.bind(this)}
                                                />
                                            </TableColumn>
                                            <TableColumn></TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>C.</TableColumn>
                                            <TableColumn colSpan={2}>Jumlah seluruh Penyerahan</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section1.jmlICDPP'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={this.props.dashboard}
                                                />
                                            </TableColumn>
                                            <TableColumn></TableColumn>
                                        </TableRow>
                                    </TableBody>
                                </DataTable>
                            </Tab>
                            <Tab style={{color: 'black'}} label="Bagian II">
                                <DataTable plain style={{width: '100%'}}>
                                    <TableBody>
                                        <TableRow>
                                            <TableColumn colSpan={5}>II. PERHITUNGAN PPN KURANG BAYAR/LEBIH BAYAR</TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn colSpan={5}>
                                                {nilaiPPN_status}
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>A.</TableColumn>
                                            <TableColumn colSpan={2}>Pajak Keluaran yang harus dipungut sendiri</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section2Ag.jmlIIAPPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn></TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>B.</TableColumn>
                                            <TableColumn colSpan={2}>PPN disetor dimuka dalam Masa Pajak yang sama</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section2Ag.jmlIIBPPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    onChange={this.hitungBagianIIB.bind(this)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Button raised primary onClick={this.redirectPPNDDM.bind(this)}>Tambah Detail</Button>
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>C.</TableColumn>
                                            <TableColumn colSpan={2}>Pajak Masukan yang dapat diperhitungkan</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section2Ag.jmlIICPPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            <TableColumn></TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>D.</TableColumn>
                                            <TableColumn colSpan={2}>PPN kurang atau lebih Bayar</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section2Ag.jmlIIDPPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                            {/* <TableColumn>
                                                <Button disabled={disabledKodeBillingD} raised primary onClick={this.handleKodeBillingD.bind(this)}>Kode Billing</Button>
                                            </TableColumn> */}
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>E.</TableColumn>
                                            <TableColumn colSpan={2}>PPN kurang atau lebih Bayar pada SPT yang dibetulkan</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section2Ag.jmlIIEPPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={!isPembetulan}
                                                    onChange={this.hitungBagianIIE.bind(this)}
                                                />
                                            </TableColumn>
                                            <TableColumn></TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>F.</TableColumn>
                                            <TableColumn colSpan={2}>PPN kurang atau lebih Bayar karena pembetulan</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section2Ag.jmlIIFPPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseFloat(e)}
                                                    disabled={!isPembetulan}
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                {/* <Button disabled={disabledKodeBillingF} raised primary onClick={this.handleKodeBillingF.bind(this)}>Kode Billing</Button> */}
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>G.</TableColumn>
                                            <TableColumn colSpan={2}>Kurang bayar dilunasi tanggal</TableColumn>
                                            <TableColumn></TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label='NTPN'
                                                    name='section2Ag.ntpnII'
                                                    className="md-cell md-cell--12"
                                                    component={Textfield}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}></TableColumn>
                                            <TableColumn colSpan={2}></TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=""
                                                    name='section2Ag.tglKrgByrII'
                                                    className="md-cell md-cell--12"
                                                    component={Datepickerv2}
                                                    locales="id-ID"
                                                    inline={true}
                                                />
                                            </TableColumn>
                                            {/* chkNKLII if == 0 */}
                                            <TableColumn><Button style={{ width: 120 }} disabled={disabledSSPII} raised primary onClick={this.selectSSP.bind(this, "2")}>SSP</Button></TableColumn>
                                        </TableRow>
                                    </TableBody>
                                </DataTable>
                            </Tab>
                            <Tab style={{color: 'black'}} label="Bagian II.H">
                                <div>
                                    <div className="md-cell md-cell--12">
                                        <p>II. Perhitungan PM yang Dapat Dikreditkan</p>
                                        <hr />
                                    </div>
                                    <div className="md-cell md-cell--12">
                                        <p>H. PPN lebih Bayar pada</p>
                                    </div>
                                    <div className="md-grid" style={{width: '100%', border: '1px solid grey'}}>
                                        <div className="md-cell md-cell--1"></div>
                                        <div className="md-cell md-cell--11" style={{display:'contents'}}>
                                            <Field
                                                className="md-cell md-cell--5"
                                                label="1.1 Butir II.D"
                                                name='section2h.chkLB1'
                                                component={Checkbox}
                                                disabled={isPembetulan || !disabledBagian2H}
                                            />
                                            <Field
                                                className="md-cell md-cell--2"
                                                label="1.2 Butir II.D"
                                                name='section2h.chkLB2'
                                                onChange={this.handleSwitchA.bind(this)}
                                                component={Checkbox}
                                                disabled={!isPembetulan || !disabledBagian2H}
                                            />
                                            <div className="md-cell md-cell--1"><p style={{paddingTop:'20px'}}>atau</p></div>
                                            <Field
                                                className="md-cell md-cell--2"
                                                label="1.2 Butir II.F"
                                                name='section2h.chkLB3'
                                                onChange={this.handleSwitchA.bind(this)}
                                                component={Checkbox}
                                                disabled={!isPembetulan || !disabledBagian2H}
                                            />
                                        </div>
                                        <div className="md-cell md-cell--1"></div>
                                        <div className="md-cell md-cell--1" style={{textAlign:'center'}}><p style={{paddingTop:'20px'}}>Oleh</p></div>
                                        <div className="md-cell md-cell--11" style={{display:'contents'}}>
                                            <Field
                                                className="md-cell md-cell--5"
                                                label="2.1 PKP Pasal 9 Ayat (4b) PPN"
                                                name='section2h.chkLB4'
                                                onChange={this.handleSwitchB.bind(this)}
                                                component={Checkbox}
                                                disabled={!disabledBagian2H}
                                            />
                                            <Field
                                                className="md-cell md-cell--5"
                                                label="2.1 Selain PKP Pasal 9 Ayat (4b) PPN"
                                                name='section2h.chkLB5'
                                                onChange={this.handleSwitchB.bind(this)}
                                                component={Checkbox}
                                                disabled={!disabledBagian2H}
                                            />
                                        </div>
                                    </div>
                                    <div className="md-cell md-cell--12">
                                        <p>Diminta Untuk</p>
                                    </div>
                                    <div className="md-grid" style={{width: '100%', border: '1px solid grey'}}>
                                        <div className="md-cell md-cell--11" style={{display:'contents'}}>
                                            <Field
                                                className="md-cell md-cell--6"
                                                label="3.1 Dikompensasikan ke Masa Pajak berikutnya"
                                                name='section2h.chkLB6'
                                                onChange={this.handleSwitchC.bind(this, 'B6')}
                                                component={Checkbox}
                                                disabled={!disabledBagian2H || checkH31}
                                            />
                                            <Field
                                                className="md-cell md-cell--2"
                                                label="    Dikompensasikan ke Masa Pajak"
                                                name='section2h.chkLB7'
                                                onChange={this.handleSwitchC.bind(this, 'B7')}
                                                component={Checkbox}
                                                disabled={this.props.dashboard || !disabledBagian2H || checkH32}
                                            />
                                            <div className="md-cell md-cell--4" style={{display:'contents'}}>
                                                <Field
                                                    style={{marginTop:'-3px'}}
                                                    label="Masa Pajak"
                                                    name='section2h.masaPajakLb'
                                                    className="md-cell md-cell--2"
                                                    component={Searchfield}
                                                    onlyNumber={true}
                                                    disabled={disabledHMasa || this.state.checkH32}
                                                    options={[
                                                        {id: "01", name: "1"},
                                                        {id: "02", name: "2"},
                                                        {id: "03", name: "3"},
                                                        {id: "04", name: "4"},
                                                        {id: "05", name: "5"},
                                                        {id: "06", name: "6"},
                                                        {id: "07", name: "7"},
                                                        {id: "08", name: "8"},
                                                        {id: "09", name: "9"},
                                                        {id: "10", name: "10"},
                                                        {id: "11", name: "11"},
                                                        {id: "12", name: "12"}
                                                    ]}
                                                />
                                                <Field
                                                    style={{marginTop:'-3px'}}
                                                    className="md-cell md-cell--2"
                                                    label="Tahun Pajak"
                                                    name='section2h.tahunPajakLb'
                                                    component={Textfield}
                                                    disabled={disabledHMasa || checkH32}
                                                />
                                            </div>
                                        </div>
                                        <Field
                                            className="md-cell md-cell--12"
                                            label="3.2 Dikembalikan (Restitusi)"
                                            name='section2h.chkLB8'
                                            onChange={this.handleSwitchC.bind(this, 'B8')}
                                            component={Checkbox}
                                            disabled={this.props.dashboard || !disabledBagian2H}
                                        />
                                    </div>
                                    <div className="md-cell md-cell--12">
                                        <p>Khusus Restitusi untuk PKP</p>
                                    </div>
                                    <div className="md-grid" style={{width: '100%', border: '1px solid grey'}}>
                                        <Field
                                            className="md-cell md-cell--12"
                                            label="Pasal 17C KUP, Dilakukan Dengan:"
                                            name='section2h.chkLB9'
                                            component={Checkbox}
                                            disabled={disabledRestitusi}
                                            onChange={this.handleSwitchC.bind(this, 'B9')}
                                        />
                                        <div className="md-cell md-cell--4"></div>
                                        <Field
                                            className="md-cell md-cell--4"
                                            label="Prosedur Biasa"
                                            name='section2h.chkLB10'
                                            component={Checkbox}
                                            disabled={disabledRestitusi}
                                            onChange={this.handleSwitchC.bind(this, 'B10')}
                                        />
                                        <Field
                                            className="md-cell md-cell--4"
                                            label="Pengembalian Pendahuluan"
                                            name='section2h.chkLB11'
                                            component={Checkbox}
                                            disabled={disabledRestitusi}
                                            onChange={this.handleSwitchC.bind(this, 'B11')}
                                        />
                                        <Field
                                            className="md-cell md-cell--12"
                                            label="Pasal 17D KUP, Dilakukan Dengan:"
                                            name='section2h.chkLB12'
                                            component={Checkbox}
                                            disabled={disabledRestitusi}
                                            onChange={this.handleSwitchC.bind(this, 'B12')}
                                        />
                                        <div className="md-cell md-cell--4"></div>
                                        <Field
                                            className="md-cell md-cell--4"
                                            label="Prosedur Biasa"
                                            name='section2h.chkLB13'
                                            component={Checkbox}
                                            disabled={disabledRestitusi}
                                            onChange={this.handleSwitchC.bind(this, 'B13')}
                                        />
                                        <Field
                                            className="md-cell md-cell--4"
                                            label="Pengembalian Pendahuluan"
                                            name='section2h.chkLB14'
                                            component={Checkbox}
                                            disabled={disabledRestitusi}
                                            onChange={this.handleSwitchC.bind(this, 'B14')}
                                        />
                                        <Field
                                            className="md-cell md-cell--12"
                                            label="Pasal 9 ayat (4c) PPN dilakukan dengan Pengembalian Pendahuluan"
                                            name='section2h.chkLB15'
                                            component={Checkbox}
                                            disabled={disabledRestitusi}
                                            onChange={this.handleSwitchC.bind(this, 'B15')}
                                        />
                                    </div>
                                </div>
                            </Tab>
                            <Tab style={{color: 'black'}} label="Bagian III, IV, dan V">
                                <DataTable plain style={{width: '100%'}}>
                                    <TableBody>
                                        <TableRow>
                                            <TableColumn colSpan={6}>III. PPN TERUTANG ATAS KEGIATAN MEMBANGUN SENDIRI</TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>A</TableColumn>
                                            <TableColumn colSpan={4}>Jumlah Dasar Pengenaan Pajak</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section3.jmlIIIA'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    onChange={this.hitung3A.bind(this)}
                                                    money={true} normalize={(e)=> parseInt(e)}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>B</TableColumn>
                                            <TableColumn colSpan={4}>PPN terutang</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section3.jmlIIIB'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseInt(e)}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>C</TableColumn>
                                            <TableColumn colSpan={2}>Dilunasi Tanggal</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section3.tglKrgByrIII'
                                                    className="md-cell md-cell--12"
                                                    component={Datepickerv2}
                                                    locales="id-ID"
                                                    inline={true}
                                                    format={(e)=> this.formatDate(e) }
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label='NTPN'
                                                    name='section3.ntpnIII'
                                                    className="md-cell md-cell--12"
                                                    component={Textfield}
                                                />
                                            </TableColumn>
                                            <TableColumn><Button style={{ width: 120 }}  disabled={disabledSSPIII} raised primary onClick={this.selectSSP.bind(this, "3")}>SSP</Button></TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn colSpan={6}>IV. PEMBAYARAN KEMBALI PAJAK MASUKAN BAGI PKP GAGAL BERPRODUKSI</TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>A</TableColumn>
                                            <TableColumn colSpan={4}>PPN yang wajib dibayar kembali	</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section4.jmlIVA'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseInt(e)}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>B</TableColumn>
                                            <TableColumn colSpan={2}>Dilunasi Tanggal</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section4.tglKrgByrIV'
                                                    className="md-cell md-cell--12"
                                                    component={Datepickerv2}
                                                    locales="id-ID"
                                                    inline={true}
                                                    format={(e)=> this.formatDate(e) }
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label='NTPN'
                                                    name='section4.ntpnIV'
                                                    className="md-cell md-cell--12"
                                                    component={Textfield}
                                                />
                                            </TableColumn>
                                            <TableColumn><Button style={{ width: 120 }}  disabled={disabledSSPIV} raised primary onClick={this.selectSSP.bind(this, "4")}>SSP</Button></TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn colSpan={6}>V. PAJAK PENJUALAN ATAS BARANG MEWAH</TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>A</TableColumn>
                                            <TableColumn colSpan={4}>PPnBM yang harus dipungut sendiri</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section5.jmlVAPPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseInt(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>B</TableColumn>
                                            <TableColumn colSpan={4}>PPnBM disetor dimuka dalam Masa Pajak yang sama</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section5.jmlVBPPN'
                                                    className="md-cell md-cell--12"
                                                    onChange={this.hitung5B.bind(this)}
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseInt(e)}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>C</TableColumn>
                                            <TableColumn colSpan={4}>PPnBM kurang atau (lebih) Bayar</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section5.jmlVCPPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseInt(e)}
                                                    disabled={true}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>D</TableColumn>
                                            <TableColumn colSpan={4}>PPnBM kurang atau (lebih) Bayar pada SPT pembetulan</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section5.jmlVDPPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseInt(e)}
                                                    disabled={!isPembetulan}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>E</TableColumn>
                                            <TableColumn colSpan={4}>PPnBM kurang atau (lebih) Bayar karena pembetulan</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section5.jmlVEPPN'
                                                    className="md-cell md-cell--12"
                                                    component={TextfieldMask}
                                                    money={true} normalize={(e)=> parseInt(e)}
                                                    disabled={disabledSSPV}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                        <TableRow>
                                            <TableColumn style={{width: 50}}>F</TableColumn>
                                            <TableColumn colSpan={2}>Dilunasi Tanggal</TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label=''
                                                    name='section5.tglKrgByrV'
                                                    className="md-cell md-cell--12"
                                                    component={Datepickerv2}
                                                    locales="id-ID"
                                                    inline={true}
                                                    format={(e)=> this.formatDate(e) }
                                                />
                                            </TableColumn>
                                            <TableColumn>
                                                <Field
                                                    label='NTPN'
                                                    name='section5.ntpnV'
                                                    className="md-cell md-cell--12"
                                                    component={Textfield}
                                                />
                                            </TableColumn>
                                            <TableColumn><Button style={{ width: 120 }}  raised disabled={disabledSSPV} primary onClick={this.selectSSP.bind(this, "5")}>SSP</Button></TableColumn>
                                        </TableRow>
                                    </TableBody>
                                </DataTable>
                            </Tab>
                            <Tab style={{color: 'black'}} label="Bagian VI">
                                <div className="md-grid" style={{width: '100%'}}>
                                    <div className="md-cell md-cell--12">
                                        <h4>VI. KELENGKAPAN SPT</h4>
                                        <hr />
                                        <h4>{nilaiPPNBM_status}</h4>
                                    </div> 
                                    <div className="md-cell md-cell--4">
                                        <Field
                                            label='Formulir 1111 AB'
                                            name='section6.chkLamp1'
                                            component={Checkbox}
                                        />
                                        <Field
                                            label='Formulir 1111 A1'
                                            name='section6.chkLamp2'
                                            component={Checkbox}
                                        />
                                        <Field
                                            label='Formulir 1111 A2'
                                            name='section6.chkLamp3'
                                            component={Checkbox}
                                        />
                                    </div>
                                    <div className="md-cell md-cell--4">
                                        <Field
                                            label='Formulir 1111 B1'
                                            name='section6.chkLamp4'
                                            component={Checkbox}
                                        />
                                        <Field
                                            label='Formulir 1111 B2'
                                            name='section6.chkLamp5'
                                            component={Checkbox}
                                        />
                                        <Field
                                            label='Formulir 1111 B3'
                                            name='section6.chkLamp6'
                                            component={Checkbox}
                                        />
                                    </div>
                                    <div className="md-cell md-cell--4">
                                        <Field
                                            label='Surat Kuasa Khusus'
                                            name='section6.chkLamp9'
                                            component={Checkbox}
                                        />
                                    </div>
                                    <div className="md-cell md-cell--6">
                                        <Field
                                            label='SSP PPn'
                                            name='section6.chkLamp7'
                                            component={Checkbox}
                                        />
                                    </div>
                                        <Field
                                            label='SSP PPn Lembar'
                                            name='section6.jmlLembar7'
                                            component={TextfieldMask}
                                            money={true}
                                            className="md-cell md-cell--6"
                                            normalize={(e)=> parseInt(e)}
                                        />
                                    <div className="md-cell md-cell--6">
                                        <Field
                                            label='SSP PPnBM'
                                            name='section6.chkLamp8'
                                            component={Checkbox}
                                        />
                                    </div>
                                    <Field
                                        label='SSP PPnBM Lembar'
                                        name='section6.jmlLembar8'
                                        component={TextfieldMask}
                                        money={true}
                                        className="md-cell md-cell--6"
                                        normalize={(e)=> parseInt(e)}
                                    />
                                    <Field
                                        label='Lainnya'
                                        name='section6.chkLamp10'
                                        component={Checkbox}
                                        className="md-cell md-cell--4"
                                    />
                                    {showLampiran10 && 
                                        <Field
                                            label='Nama Lainnya'
                                            name='section6.nmLamp10'
                                            component={Textfield}
                                            className="md-cell md-cell--4"
                                        />
                                    }
                                    {showLampiran10 && 
                                        <Field
                                            label='Jumlah Lembar Lainnya'
                                            name='section6.jmlLembar10'
                                            component={TextfieldMask}
                                            money={true}
                                            className="md-cell md-cell--4"
                                            normalize={(e)=> parseInt(e)}
                                        />
                                    }
                                    {/* <Field
                                        label='Tempat'
                                        name='section6.kotaLapor'
                                        component={Textfield}
                                        className="md-cell md-cell--6"
                                    /> */}
                                    <Field
                                        label='Tanggal'
                                        name='section6.tglSPT'
                                        component={Datepickerv2}
                                        locales="id-ID"
                                        inline={true}
                                        className="md-cell md-cell--12"
                                    />
                                    <Field
                                        label='PKP'
                                        name='section6.chkPKP'
                                        component={Checkbox}
                                        className="md-cell md-cell--2"
                                        onChange={(e)=> this.chkPkp(e)}
                                    />
                                    <div className="md-cell md-cell--10" />
                                    {/* <Field
                                        label='Nama Jelas'
                                        name='section6.kotaLapor'
                                        component={Textfield}
                                        className="md-cell md-cell--10"
                                    /> */}
                                    <Field
                                        label='KUASA'
                                        name='section6.chkKuasa'
                                        component={Checkbox}
                                        className="md-cell md-cell--2"
                                        onChange={(e)=> this.chkKuasa(e)}
                                    />
                                    <div className="md-cell md-cell--10" />
                                    <Field
                                        label='Kota Lapor'
                                        name='section6.kotaLapor'
                                        component={Textfield}
                                        className="md-cell md-cell--12"
                                    />
                                    <Field
                                        label='Nama Penandatanganan'
                                        name='section6.namaPenandatangan'
                                        component={Textfield}
                                        className="md-cell md-cell--12"
                                    />
                                    <Field
                                        label='Jabatan Penandatanganan'
                                        name='section6.jabatanPenandatangan'
                                        component={Textfield}
                                        className="md-cell md-cell--12"
                                    />
                                    <div className="md-cell md-cell--8">
                                        <p style={{padding: 24, border: "1px solid black"}}>
                                            PERNYATAAN :
                                            DENGAN MENYADARI SEPENUHNYA AKAN SEGALA AKIBATNYA, SAYA MENYATAKAN BAHWA APA YANG TELAH SAYA BERITAHUKAN DIATAS BESERTA LAMPIRAN-LAMPIRANNYA ADALAH BENAR, LENGKAP, JELAS DAN TIDAK BERSYARAT
                                        </p>
                                    </div>
                                    <div className="md-cell md-cell--4">
                                        <Button onClick={this.props.handleSubmit(this.handleSave.bind(this))} raised primary style={{width: '100%', height: '90%', padding: 0, margin: 0 }}>SAVE</Button>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </TabsContainer>
                </Card>
            </div>
        )
    }

};

export default Induk1111FormView;